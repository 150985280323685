import "../css/mycss.css";
import React, { useEffect } from "react";

import rectangle from "../img/rectangle.png";
import bienesraices from "../img/bienesraices.png";
import blogcafe from "../img/blogcafe.png";
import cocacola from "../img/cocacola.png";

import icon_adelante from "../img/icon_adelante.png";
import icon_atras from "../img/icon_atras.png";

function Portfolio() {
  const items = [
    { id: 1, title: "Bienes raíces", img: bienesraices, link_page:"https://bienesracesbycrsolutions.netlify.app" },
    { id: 2, title: "Blog Café", img: blogcafe, link_page:"https://blogcafebycrsolutions.netlify.app" },
    { id: 3, title: "Coca Cola", img: cocacola, link_page:"https://cocacolayaxel.netlify.app" }
  ];

  function cantidad_elementos_mostrables() {
    const window_width = window.innerWidth;
    if (window_width < 600) return 1;
    // if (window_width < 800) return 3;
    return 3;
  }
  


  useEffect(() => {
    let startIndex = 0;

    const updateVisibleItems = () => {
      const elementos = document.querySelectorAll(".item");
      const mostrables = cantidad_elementos_mostrables();

      elementos.forEach((el, index) => {
        el.hidden = index < startIndex || index >= startIndex + mostrables;
      });
    };

    const handleResize = () => {
      updateVisibleItems();
    };

    const handleNext = () => {
      const elementos = document.querySelectorAll(".item");
      const mostrables = cantidad_elementos_mostrables();
      if (startIndex + mostrables < elementos.length) {
        startIndex++;
        updateVisibleItems();
      }
    };

    const handlePrevious = () => {
      if (startIndex > 0) {
        startIndex--;
        updateVisibleItems();
      }
    };

    document.getElementById("i_adelante").addEventListener("click", handleNext);
    document
      .getElementById("i_atras")
      .addEventListener("click", handlePrevious);
    window.addEventListener("resize", handleResize);

    updateVisibleItems();

    return () => {
      document
        .getElementById("i_adelante")
        .removeEventListener("click", handleNext);
      document
        .getElementById("i_atras")
        .removeEventListener("click", handlePrevious);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="portfolio" className="vh-100 portfolio">
      <div className="portfolio-info">
        <h2>Portafolio</h2>
        <p>
          En esta sección podrás encontrar una selección de mis trabajos más
          recientes, incluyendo sitios web para empresas de diversos sectores,
          tiendas online, blogs y landing pages. Cada proyecto ha sido diseñado
          y desarrollado a medida para satisfacer las necesidades específicas de
          cada cliente.
        </p>
      </div>
      <div className="portfolio__examples">
        <div className="portfolio__examples__controls">
          <div className="carrousel__button" id="i_atras">
            <img src={icon_atras} alt="" />
          </div>
        </div>
        <div className="portfolio__examples__carrousel">
          {items.map((item) => (
            <div className="item" key={item.id}>
              <a href={item.link_page} target="_blank">
                <img src={item.img} alt="" />
              </a>
              <span>{item.title}</span>
            </div>
          ))}
        </div>
        <div className="portfolio__examples__controls">
          <div className="carrousel__button" id="i_adelante">
            <img src={icon_adelante} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
