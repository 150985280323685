import "../css/mycss.css";
import React, { useEffect } from "react";
import { ShowCatMessage } from "../js/utils.js";

function Services() {
  useEffect(() => {
    function elegirPlan(event, plan) {
      event.preventDefault();
      let mensaje = "";

      switch (plan) {
        case "plan_esencial":
          mensaje = `Me gustaría recibir más detalles sobre el paquete "PLAN ESENCIAL" para mi página web. ¿Podrían proporcionarme información más detallada sobre el diseño responsive, el número de páginas incluidas, si hay un formulario de contacto y la integración con redes sociales?`;
          break;

        case "plan_popular":
          mensaje = `Estoy interesado en obtener más detalles sobre el paquete "PLAN POPULAR" para mi página web. ¿Podrían proporcionarme más información sobre el diseño web personalizado y optimizado para móviles, el número de páginas incluidas, la funcionalidad del blog y la tienda online, así como el posicionamiento SEO básico?`;
          break;

        default:
          mensaje = `Me gustaría recibir más información sobre el paquete "PLAN EMPRESARIAL" para mi página web. ¿Podrían proporcionarme detalles sobre el diseño web a medida y optimizado para dispositivos móviles, la disponibilidad de páginas ilimitadas, las características del blog completo y la tienda online avanzada, así como información sobre el posicionamiento SEO completo y el marketing en redes sociales?`;
          break;
      }

      document.getElementById("message").value = mensaje;
      ShowCatMessage("Ahora solo falta llenar tus datos.");

      document
        .getElementById("contactame")
        .scrollIntoView({ behavior: "smooth" });
    }

    document
      .getElementById("plan_esencial")
      .addEventListener("click", function (event) {
        elegirPlan(event, "plan_esencial");
      });

    document
      .getElementById("plan_popular")
      .addEventListener("click", function (event) {
        elegirPlan(event, "plan_popular");
      });

    document
      .getElementById("plan_empresarial")
      .addEventListener("click", function (event) {
        elegirPlan(event, "plan_empresarial");
      });
    return () => {
      document
        .getElementById("plan_esencial")
        .removeEventListener("click", elegirPlan);
      document
        .getElementById("plan_popular")
        .removeEventListener("click", elegirPlan);
      document
        .getElementById("plan_empresarial")
        .removeEventListener("click", elegirPlan);
    };
  }, []);

  return (
    <div id="servicios" className="background-blue vh-100 services">
      <h2 className="services-title">Servicios</h2>
      <div className="services-types">
        <div className="plan_service background-white">
          <h3>PLAN ESENCIAL</h3>
          <ul>
            <li>Diseño web responsive y optimizado para móviles</li>
            <li>Hasta 5 páginas</li>
            <li>Formulario de contacto</li>
            <li>Integración con redes sociales</li>
          </ul>
          <button id="plan_esencial">S/499.99</button>
        </div>
        <div className="plan_service background-white">
          <h3>PLAN POPULAR</h3>
          <ul>
            <li>Diseño web personalizado y optimizado para móviles</li>
            <li>Hasta 10 páginas</li>
            <li>Blog</li>
            <li>Tienda online</li>
            <li>Posicionamiento SEO básico</li>
          </ul>
          <button id="plan_popular">S/699.99</button>
        </div>
        <div className="plan_service background-white">
          <h3>PLAN EMPRESARIAL</h3>
          <ul>
            <li>Diseño web a medida y optimizado para móviles</li>
            <li>Páginas ilimitadas</li>
            <li>Blog completo</li>
            <li>Tienda online avanzada</li>
            <li>Posicionamiento SEO completo</li>
            <li>Marketing en redes sociales</li>
          </ul>
          <button id="plan_empresarial">S/799.99</button>
        </div>
      </div>
    </div>
  );
}

export default Services;
