import '../css/mycss.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import white_logo from "../img/white-logo.svg";

let logoMomentaneo = white_logo;

const icon_facebook = <FontAwesomeIcon icon={faFacebook} />
const icon_whatsapp = <FontAwesomeIcon icon={faWhatsapp} />
const icon_email = <FontAwesomeIcon icon={faEnvelope} />


function presentation() {
  return (
    <footer className="vh-35 background-black footer">
      <div>
        <span className=''><img src={logoMomentaneo} alt="logo" className='logo_footer' /></span>
        <span className=''>empty</span>
        <div className='footer_social'>
          <span>Redes Sociales</span>
          <div>
            {icon_facebook}
            {icon_whatsapp}
            {icon_email}
            {/* PODEMOS AGREGAR LINKEDIN */}
          </div>
        </div>
        
      </div>
      <span>all rights reserved to axelcavero.com 2024</span>
    </footer>
  );
}

export default presentation;
