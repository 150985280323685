function limpiar_campos() {
    document.getElementById("name").value = "";
    document.getElementById("last_name").value = "";
    document.getElementById("email").value = "";
    document.getElementById("phone").value = "";
    document.getElementById("message").value = "";
}
function ShowCatMessage(text, display = "block") {
    document.querySelector(".shadow").textContent = text;
    document.getElementById("cloud").parentElement.style.display = display;
  }
export { limpiar_campos, ShowCatMessage };