import PresentationComponent from './components/Presentation';
import PortfolioComponent from './components/Portfolio';
import ServicesComponent from './components/Services';
import ContactmeComponent from './components/Contactme';
import FooterComponent from './components/Footer';
import './css/mycss.css';

function App() {
  return (
    <div className="App">
      <PresentationComponent />
      <PortfolioComponent />
      <ServicesComponent/>
      <ContactmeComponent/>
      <FooterComponent/>
    </div>
  );
}

export default App;
