import "../css/mycss.css";
import HeaderComponent from "./Header";
import React from "react";
import Typed from "typed.js";

function Presentation() {
  const frase_1 = React.useRef(null);
  const frase_2 = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(frase_1.current, {
      strings: [
        "CR Solutions^3000",
        "Creamos soluciones digitales a su medida.^3000",
      ],
      typeSpeed: 100,
      loop: true,
      loopCount: Infinity,
    });
    const typed_2 = new Typed(frase_2.current, {
      strings: [
        `Soy un apasionado del diseño web y del marketing digital. Mi objetivo es ayudarte a crear una página web que te represente a ti y a tu marca, y que te ayude a alcanzar tus objetivos. Creo que cada página web es única, por eso me tomo el tiempo para comprender tus necesidades específicas y trabajar en estrecha colaboración contigo durante todo el proceso de creación.`,
      ],
      typeSpeed: 5,
      showCursor: false
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return (
    <div id="presentation" className="vh-100 background-black">
      <HeaderComponent />
      <div className="presentation">
        <div hidden>
          <h1>CR Solutions</h1>
          <p>
            Soy un apasionado del diseño web y del marketing digital. Mi
            objetivo es ayudarte a crear una página web que te represente a ti y
            a tu marca, y que te ayude a alcanzar tus objetivos. <br />
            Creo que cada página web es única, por eso me tomo el tiempo para
            comprender tus necesidades específicas y trabajar en estrecha
            colaboración contigo durante todo el proceso de creación.
          </p>
        </div>
        <div id="typed-strings">
          <h1 className="presentation__principal"><span ref={frase_1}></span></h1>
          <p className="presentation__description"><span ref={frase_2}></span></p>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
