import "../css/mycss.css";
import { limpiar_campos, ShowCatMessage } from "../js/utils.js";
import React, { useEffect } from "react";

function Contactme() {
  useEffect(() => {
    async function sendForm(e) {
      e.preventDefault();
      let name = document.getElementById("name").value;
      let last_name = document.getElementById("last_name").value;
      let email = document.getElementById("email").value;
      let phone = document.getElementById("phone").value;
      let message = document.getElementById("message").value;

      const data = {
        name: name,
        last_name: last_name,
        email: email,
        phone: phone,
        text: message,
      };

      let control = 0;
      for (const key in data) {
        if (data[key].trim() === "") {
          ShowCatMessage("Llena todos los campos");
          control = 1;
        } 
      }
      if (control === 0) {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        limpiar_campos();
        ShowCatMessage("ENVIANDO MENSAJE...");
        fetch(
          "https://mailer-q5najkiqz-axel-caveros-projects.vercel.app/send-email",
          options
        )
          .then((response) => response.json())
          .then((data) => {
            let estado = data.result;
            if (estado === "DONE") {
              console.log(data.result);
              ShowCatMessage("MENSAJE ENVIADO");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
    
    document.getElementById("form_button").addEventListener("click", sendForm);
  }, []);

  return (
    <div id="contactame" className="background-black contactme">
      <div className="contactme-title">
        <h2>Contáctame</h2>
      </div>
      <div className="contactme-sections">
        <div className="contactme-sections-form">
          <form id="contactme-form" action="#">
            <div className="contactme-inputs">
              <div>
                <label htmlFor="">Nombre</label>
                <input id="name" type="text" />
              </div>
              <div>
                <label htmlFor="">Apellido</label>
                <input id="last_name" type="text" />
              </div>
              <div>
                <label htmlFor="">Correo Electrónico</label>
                <input id="email" type="email" />
              </div>
              <div>
                <label htmlFor="">Teléfono</label>
                <input id="phone" type="tel" />
              </div>
            </div>
            <label htmlFor="">Mensaje</label>
            <textarea id="message" name="message"></textarea>
            <button id="form_button" className="form_button" type="submit">
              Enviar
            </button>
          </form>
        </div>
        <div className="contactme-sections-img">
          <div style={{ display: "none" }}>
            <div id="cloud">
              <span class="shadow"></span>
            </div>
          </div>
          <iframe
            title="Cat typing"
            src="https://giphy.com/embed/oHoASEzW8HppPux0Si"
            className="giphy-embed"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contactme;
