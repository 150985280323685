import "../css/mycss.css";
import white_logo from "../img/white-logo.svg";
import icon_menu from "../img/icon_menu.png";
import React, { useEffect } from 'react';


function Header() {
  let logoMomentaneo = white_logo;
  useEffect(() => {
    const element_icon_menu = document.getElementById('icon_menu');
    const element_navigation__block = document.getElementById('navigation__block');
     

    function handleIconMenuClick() {
      let display_navigation__block = window.getComputedStyle(element_navigation__block).display;
      console.log(display_navigation__block);
      if (display_navigation__block === "block") {
        element_navigation__block.style.display = 'none';
      }else{
        element_navigation__block.style.display = 'block';
      }
    }

    function hiddenNavBlockOnResize(width_screen = window.innerWidth) {
      if (width_screen >= 900) {
        element_navigation__block.style.display = 'none';
      }
    }
    
    element_icon_menu.addEventListener('click', handleIconMenuClick);
    window.addEventListener('resize', hiddenNavBlockOnResize);

    return () => {
      window.removeEventListener('resize', hiddenNavBlockOnResize);
      element_icon_menu.removeEventListener('click', handleIconMenuClick)
    };
  }, []);
 

  return (
    <header className="background-black header">
      <nav className="navigation">
        <div className="navigation__left">
          <a className="text-color-white font-size-12" href="#presentation">
            Inicio
          </a>
        </div>
        <div className="navigation__right">
          <a className="text-color-white font-size-12" href="#portfolio">
            Portafolio
          </a>
          <a className="text-color-white font-size-12" href="#servicios">
            Servicios
          </a>
          <a className="text-color-white font-size-12" href="#contactame">
            Contáctame
          </a>
        </div>
      </nav>
      <nav className="navigation__hamburguer">
        <div className="navigation__buttons">
          <div>
            <a id="icon_menu" className="icon_menu" >
              <img src={icon_menu} alt="" />
            </a>
          </div>
          <div>
            <a className="logo" href="/#">
              <img src={logoMomentaneo} alt="" />
            </a>
          </div>
          <div></div>
        </div>
        <div id="navigation__block" className="navigation__block">
          <a className="text-color-white font-size-12" href="#presentation">
            Inicio
          </a>
          <a className="text-color-white font-size-12" href="#portfolio">
            Portafolio
          </a>
          <a className="text-color-white font-size-12" href="#servicios">
            Servicios
          </a>
          <a className="text-color-white font-size-12" href="#contactame">
            Contáctame
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
